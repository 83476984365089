<template>
  <div class="Followuplist">
    <div class="title">
      <div class="seach">
        <div class="inputs">
          <el-select
            v-model="from.logistic_business_id"
            clearable
            placeholder="选择集配中心"
          >
            <el-option
              v-for="item of logisticList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
            <el-select
            filterable
            v-model="from.logistics_id"
            clearable
            placeholder="请选择城市仓"
          
          >
            <el-option
              v-for="item in cityStoreList"
              :key="item.id"
              :label="item.name"
              :value="item.id.toString()"
            >
            </el-option>
          </el-select>
        </div>
       
        <div class="inputs">
          <el-input
            v-model="from.fullname"
            placeholder="请输入用户姓名"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from.mobile"
            placeholder="请输入用户电话"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
            <el-select v-model="from.follow_type" clearable placeholder="请选择回访结果">
            <el-option label="无效客户" value="1"></el-option>
            <el-option label="商品质量差" value="2"></el-option>
            <el-option label="商城不好用" value="3"></el-option>
            <el-option label="价格贵" value="4"></el-option>
            <el-option label="客诉慢" value="5"></el-option>
            <el-option label="送货慢" value="6"></el-option>
            <el-option label="业务需求减少" value="7"></el-option>
            <el-option label="待再次回访" value="8"></el-option>
            <el-option label="其他" value="9"></el-option>
          </el-select>
        </div>
        <div class="inputs">
            <div class="divname">最后回访时间：</div>
          <el-date-picker
            @change="payChange"
            v-model="pay_at"
            :time-arrow-control="true"
            :clearable="false"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="—"
            start-placeholder="最后回访时间起"
            end-placeholder="最后回访时间止"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-input
            v-model="from.sale_name"
            placeholder="请输入城市仓销售"
            clearable
          ></el-input>
        </div>
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="Refresh"
        ></el-button>
        <el-button @click="search" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button @click="exportHandle" type="warning" icon="el-icon-upload2"
          >导出</el-button
        >
      </div>
    </div>

    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        ref="multipleTable"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="fullname" align="center" label="客户名称">
        </el-table-column>
        <el-table-column prop="mobile" align="center" label="联系电话">
        </el-table-column>
        <el-table-column prop="logistics_name" align="center" label="城市仓">
        </el-table-column>
        <el-table-column prop="shopname" align="center" label="店铺名称">
        </el-table-column>
        <el-table-column prop="industry_txt" align="center" label="从事行业">
        </el-table-column>
        <el-table-column prop="create_time" align="center" label="注册时间">
        </el-table-column>
        <el-table-column prop="create_days" align="center" label="注册天数">
        </el-table-column>
        <el-table-column prop="follow_num" align="center" label="回访次数">
        </el-table-column>
        <el-table-column
          prop="type_txt"
          align="center"
          label="最后一次回访结果"
        >
        </el-table-column>
        <el-table-column
          prop="follow_time"
          align="center"
          label="最后一次回访时间"
        >
        </el-table-column>
        <el-table-column prop="op_name" align="center" label="所属城市仓销售">
        </el-table-column>
        <el-table-column prop="remark" align="center" label="备注" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="gopath(scope.row)">回访记录</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
          <script>
import { postExportmember_follow_record } from "@/api/allExport";
import { downLoadFlowBlob } from "@/utils/tools/base";
import { formatDate,getQuarterStartAndEnd } from "@/utils/tools/date.js";
export default {
  name: "Followuplist",
  components: {},
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      pay_at: getQuarterStartAndEnd(),
      from: {
        start_date: getQuarterStartAndEnd()[0], //	string	开始日期
        end_date: getQuarterStartAndEnd()[1], //	string	结束日期
        logistic_business_id: "", //	number	集配中心ID
        logistics_id: "", //	number	城市仓id
        fullname: "", //	string	用户名
        mobile: "", //	string	手机号
        area_manager: "", //	string	区域经理（暂无）
        sale_name: "", //	string	销售名称
        follow_type: "", //	number	回访结果
        page: 1,
        pageSize: 50,
      },
      from1: {},
      tableData: [],
      currentPage: 1,
      total: 0,
      logisticList: [], // 集配中心列表
      cityStoreList: [], // 集配中心列表
    };
  },
  created() {
    this.from1 = JSON.parse(JSON.stringify(this.from));
    this.hqlist();
    this.ajaxGetLogisticList();
    this.citylist();
  },
  methods: {
    // 获取城市仓列表
    async citylist() {
      //   this.loadingCityStore = true;
      try {
        const res = await this.$api.Followup.logisticsselect({
          name: "",
          page: 1,
          pageSize: 10000,
        });
        console.log(res, "获取数据");
        this.cityStoreList = res.data;
        console.log(this.cityStoreList, "this.cityStoreList==");
      } catch (error) {
        console.log(error, "logisticsList");
      } finally {
        // this.loadingCityStore = false;
      }
    },
    /**
     * 获取集配中心列表
     */
    async ajaxGetLogisticList() {
      try {
        const { data } = await this.$api.general.businessList();
        this.logisticList = data;
      } catch (err) {
        console.log("ajax ajaxGetLogisticList err", err);
      }
    },
    payChange(val) {
      if (val) {
        this.from.start_date = val[0];
        this.from.end_date = val[1];
      } else {
        this.pay_at = getQuarterStartAndEnd();
        this.from.start_date =  getQuarterStartAndEnd()[0];
        this.from.end_date =  getQuarterStartAndEnd()[1];
      }
    },

    getList(e) {
      if (e.id) {
        this.hqlist();
      } else {
        this.search();
      }
    },
    //导出
    exportHandle() {
      let params = {
        ...this.from1,
      };
      delete params.page;
      delete params.pageSize;
      this.$api.Followup.member_follow_record_listexport(params).then((res) => {
        console.log(res);
        this.goExportCenter();
      });
    },

    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    gopath(e) {
      this.$router.push({
        path: "/Followuprecords",
        query: {
          mobile: e.mobile,
          start_date: this.from1.start_date, //	string	开始日期
          end_date: this.from1.end_date, //	string	结束日期
        },
      });
    },
    getList(e) {
      if (e.id) {
        this.hqlist();
      } else {
        this.search();
      }
    },
    hqlist() {
      this.loading = true;
      this.$api.Followup.member_follow_record_list(this.from1)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.data;
          this.total = res.data.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    search() {
      this.from.page = 1;
      this.from1 = JSON.parse(JSON.stringify(this.from));
      this.currentPage = 1;
      this.hqlist();
    },
    addclick() {
      console.log("新增");
    },

    Refresh() {
      Object.assign(this.$data.from, this.$options.data().from);
      this.from1 = JSON.parse(JSON.stringify(this.from));
      this.pay_at = getQuarterStartAndEnd();
      this.currentPage = 1;
      this.hqlist();
    },
    handleSizeChange(val) {
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.hqlist();
    },
  },
};
</script>
<style lang="css"> .el-tooltip__popper{ max-width:50% } </style>
          <style lang="scss">
          
.Followuplist {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .title {
    background: #ffffff;
    padding: 10px 15px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;

    .seach {
      flex: 1;
      display: flex;
      // justify-content: flex-end;
      align-items: flex-start;
      flex-wrap: wrap;

      .inputs {
        // width: 207px;
        display: flex;
        align-items: center;
        margin-right: 8px;
        margin-bottom: 10px;
      }

      .multiple-select {
        width: 250px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
          
        